import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f7f9fc;
  padding: 20px;
`;

const NotFoundText = styled.h1`
  font-size: 100px;
  font-weight: 900;
  color: #ff6b6b;
  margin-bottom: 0;
  animation: bounce 2s infinite;
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`;

const SubText = styled.p`
  font-size: 24px;
  color: #333;
  margin-bottom: 40px;
`;

function NotFoundPage() {
  return (
    <NotFoundContainer>
      <NotFoundText>404</NotFoundText>
      <SubText>Oops! The page you're looking for doesn't exist.</SubText>
    </NotFoundContainer>
  );
}

export default NotFoundPage;

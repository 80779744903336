import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'; // Use NavLink for navigation
import Header from './Header-New'; // Import the Header component

const LandingPage = () => {
  return (
    <Wrapper>
      <Header />
      <MainSection>
        <Sidebar>
          <SidebarTitle>Navigation</SidebarTitle>
          <NavItem exact to="/zenoti/getting/started">Get Started</NavItem>
          <NavItem exact to="/zenoti/register">Register / Account Details</NavItem>
          <NavItem exact to="/zenoti/displayCenters">Center Management</NavItem>
          <NavItem exact to="/zenoti/displayServicesAndExams">Services & Exams Management</NavItem>
        </Sidebar>
        <Content>
          <Card>
            <CardTitle>Zenoti-Qualiphy Integration: Revolutionizing Healthcare and Wellness Management</CardTitle>
            <CardSubtitle>Welcome to the Zenoti-Qualiphy Integration platform!</CardSubtitle>
            <CardContent>
              This powerful integration merges the strengths of Zenoti's all-in-one POS solution with the comprehensive healthcare management tools offered by Qualiphy. It empowers healthcare providers and wellness centers alike to streamline operations and enhance patient engagement.
            </CardContent>
            <CardHeading>Why Integrate Zenoti and Qualiphy?</CardHeading>
            <CardContent>
              Qualiphy is a robust platform designed to optimize healthcare practices. It automates workflows, manages practice exams, handles patient invites, and ensures compliance with industry standards. On the other hand, Zenoti is a trusted POS solution widely used by salons, spas, medical spas, and fitness centers around the world. Zenoti simplifies payment processing, invoicing, and reporting, helping businesses deliver a smooth customer experience.
            </CardContent>
            <CardContent>
              By integrating Qualiphy’s healthcare management capabilities with Zenoti’s POS efficiency, this solution offers healthcare providers, wellness centers, and spas an end-to-end system to automate administrative tasks, enhance patient engagement, streamline payments, and boost operational efficiency.
            </CardContent>
            <CardHeading>Unlock Powerful Endpoints and Features</CardHeading>
            <CardContent>
              With this integration, healthcare providers can leverage Qualiphy’s API to manage critical functions such as practice exams, patient data, attachments, and more. At the same time, they can take advantage of Zenoti’s seamless POS capabilities for efficient transactions and reporting.
            </CardContent>
            <CardContent>
              Explore the comprehensive API documentation to discover how you can elevate your healthcare or wellness practice by combining the strengths of Zenoti and Qualiphy. Whether you are automating exam workflows, processing payments, or ensuring regulatory compliance, this integration offers a complete solution tailored for modern healthcare and wellness operations.
            </CardContent>
            <CardButton href="/zenoti/register">Get Started with Zenoti Integration</CardButton>
          </Card>
        </Content>
      </MainSection>
    </Wrapper>
  );
};

export default LandingPage;

// Styled Components
const Wrapper = styled.div`
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: row; /* Align the sidebar and content in a row */
  align-items: flex-start; /* Align items at the start for better structure */
  padding: 50px 20px;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.9);
`;

const Sidebar = styled.nav`
  background-color: #1f2937;
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-right: 40px;
  width: 250px;
  position: sticky;
  top: 20px;
  height: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const SidebarTitle = styled.h3`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const NavItem = styled(NavLink)`
  display: block;
  padding: 10px 0;
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    color: #e5e7eb;
    background-color: #374151;
  }

  &.active {
    color: #2563eb;
    background-color: #1f2937;
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 1500px;
  justify-content: center;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 60px;
  max-width: 100%;
  text-align: left;
  position: relative;
  z-index: 2;
  opacity: 0.95;
`;

const CardTitle = styled.h2`
  font-size: 36px;
  color: #1f2937;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const CardSubtitle = styled.h3`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const CardHeading = styled.h4`
  font-size: 22px;
  color: #1f2937;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const CardContent = styled.p`
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const CardButton = styled.a`
  background-color: #1f2937;
  color: white;
  padding: 15px 40px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 30px;
  transition: background-color 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  display: inline-block;
  margin-top: 20px;

  &:hover {
    background-color: #111827;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

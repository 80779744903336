import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader'; // Loader
import Header from './Header-New'; // Import the existing header
import { NavLink, Link } from 'react-router-dom'; // Use NavLink for navigation
import { apiHost } from '../constants';

const DisplayPage = () => {
  const location = useLocation();
  const zenotiApiKey = location.state?.zenotiApiKey || localStorage.getItem('zenotiApiKey') || ''; // Access Zenoti API Key from state
  const [centers, setCenters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State for loader during submit
  const [editableApiKey, setEditableApiKey] = useState(zenotiApiKey);
  const [webhookIntegrationKey, setWebhookIntegrationKey] = useState();
  const [qualiphyKeys, setQualiphyKeys] = useState({});
  const [selectedCenters, setSelectedCenters] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  useEffect(() => {
    const fetchCenters = async () => {
      setIsLoading(true);
      setShowErrorBanner(false);
      try {
        const response = await fetch(`${apiHost}/zenoti/centers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            zenotiApiKey: editableApiKey,
          }),
        });
        const data = await response.json();
        console.log('Centers', data);

        if (data && data.success && data.body.centers) {
          setCenters(data.body.centers);
          setWebhookIntegrationKey(data.body.qualiphyIntegrationKey);
          console.log("selectedCenters", selectedCenters);
          setSelectedCenters(prevSelectedCenters => {
            const newSelectedCenters = { ...prevSelectedCenters };
            data.body.existingCenters.forEach(center => {
              newSelectedCenters[center.zenotiCenterId] = true;
            });
            return newSelectedCenters;
          });
          setQualiphyKeys(prevSelectedAPIKeys => {
            const newSelectedApiKeys = { ...prevSelectedAPIKeys };
            data.body.existingCenters.forEach(center => {
              newSelectedApiKeys[center.zenotiCenterId] = center.qualiphyApiKey;
            });
            return newSelectedApiKeys;
          });
          console.log(selectedCenters);
        } else {
          setErrorMessage(data.message || data.error.messgae || 'Failed to fetch centers.');
          setCenters([]);
          setWebhookIntegrationKey('');
          setShowErrorBanner(true);
        }
      } catch (error) {
        setErrorMessage('Error fetching centers: ', error.message || error || 'Service Failed');
        setCenters([]);
        setWebhookIntegrationKey('');
        setShowErrorBanner(true);
        console.error('Error fetching centers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (editableApiKey) {
      fetchCenters();
    }
  }, [editableApiKey]);

  const handleQualiphyKeyChange = (centerId, value) => {
    setQualiphyKeys(prevState => ({
      ...prevState,
      [centerId]: value,
    }));
  };

  const handleCenterSelect = (centerId, isChecked) => {
    setSelectedCenters(prevState => ({
      ...prevState,
      [centerId]: isChecked,
    }));
  };

  const updateZenotiApiKey = (newKey) => {
    localStorage.setItem('zenotiApiKey', newKey);
    setEditableApiKey(newKey);
  };

  const handleSubmit = async () => {
    const selectedData = centers.filter(center => selectedCenters[center.id]);
    console.log(selectedData);

    const hasEmptyKeys = selectedData.some(center => !qualiphyKeys[center.id]);

    if (hasEmptyKeys) {
      setErrorMessage('Please enter the Qualiphy API Key for all selected centers.');
      setShowErrorBanner(true);
      return;
    }

    const dataToSend = selectedData.map(center => ({
      zenotiCenterId: center.id,
      qualiphyApiKey: qualiphyKeys[center.id],
      zenotiCenterName: center.display_name
    }));

    const requestBody = {
      zenotiApiKey: editableApiKey,
      centersData: dataToSend,
    };

    console.log(requestBody);

    setIsSubmitting(true); // Show loader

    try {
      const response = await fetch(`${apiHost}/zenoti/addCenters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setSuccessMessage(result.message || 'Centers integrated successfully.');
        setShowSuccessBanner(true);
      } else {
        setErrorMessage(result.message || 'Failed to integrate centers. Please try again.');
        setShowErrorBanner(true);
      }
    } catch (error) {
      setErrorMessage('Error sending data. Please try again.');
      setShowErrorBanner(true);
      console.error('Error sending data:', error);
    } finally {
      setIsSubmitting(false); // Hide loader after request
    }
  };

  // Auto dismiss banners after 10 seconds
  useEffect(() => {
    if (showErrorBanner) {
      const timer = setTimeout(() => {
        setShowErrorBanner(false);
        setErrorMessage(''); // Clear message
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showErrorBanner]);

  useEffect(() => {
    if (showSuccessBanner) {
      const timer = setTimeout(() => {
        setShowSuccessBanner(false);
        setSuccessMessage(''); // Clear message
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessBanner]);

  const handleCloseErrorBanner = () => {
    setShowErrorBanner(false);
    setErrorMessage(''); // Clear message
  };

  const handleCloseSuccessBanner = () => {
    setShowSuccessBanner(false);
    setSuccessMessage(''); // Clear message
  };

  return (
    <Wrapper>
      <Header />
      <MainSection>
        <ToastContainer>
          {showErrorBanner && (
            <Toast type="error">
              {errorMessage}
              <SectionBreak /> {/* Section break between message and close button */}
              <CloseButton type="error" onClick={handleCloseErrorBanner}>&times;</CloseButton>
            </Toast>
          )}
          {showSuccessBanner && (
            <Toast type="success">
              {successMessage}
              <SectionBreak />
              <CloseButton type="success" onClick={handleCloseSuccessBanner}>&times;</CloseButton>
            </Toast>
          )}
        </ToastContainer>
        <Sidebar>
          <SidebarTitle>Navigation</SidebarTitle>
          <NavItem exact to="/zenoti/getting/started">Get Started</NavItem>
          <NavItem exact to="/zenoti/register">Register / Account Details</NavItem>
          <NavItem exact to="/zenoti/displayCenters">Center Management</NavItem>
          <NavItem exact to="/zenoti/displayServicesAndExams">Services & Exams Management</NavItem>
        </Sidebar>
        <Content>
          <ContentContainer>
            <Title>Zenoti-Qualiphy Integration: Centers Selection</Title>
            <FormSection>
              <FormWrapper>
                <Label htmlFor="zenotiApiKey">Zenoti API Key:</Label>
                <InputField
                  id="zenotiApiKey"
                  type="text"
                  value={editableApiKey}
                  onChange={(e) => updateZenotiApiKey(e.target.value)}
                  placeholder="Enter Zenoti API Key"
                  required
                />
              </FormWrapper>

              <FormWrapper>
                <Label htmlFor="webhookIntegrationKey">Webhook Integration Key:</Label>
                <InputField
                  id="webhookIntegrationKey"
                  type="text"
                  value={webhookIntegrationKey}
                  disabled
                />
              </FormWrapper>
            </FormSection>

            <CustomLink>
              Here you will find a list of centers associated with your Zenoti Account. In order to map services for a center to Qualiphy Exams, you will need to select at least one center and include the Qualiphy API Key. For more information on finding your Qualiphy API Key, please visit <Link to="/zenoti/displayCenters">Qualiphy API Key Link</Link>
            </CustomLink>

            {isLoading ? (
              <LoaderWrapper>
                <ClipLoader color={'#1f2937'} loading={true} size={50} />
              </LoaderWrapper>
            ) : (
              <ResponsiveTableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Zenoti Center Name</th>
                      <th>Zenoti Center Id</th>
                      <th>Qualiphy API Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {centers.length > 0 ? (
                      centers.map((center) => (
                        <tr key={center.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={!!selectedCenters[center.id]}
                              onChange={(e) => handleCenterSelect(center.id, e.target.checked)}
                            />
                          </td>
                          <td>{center.display_name}</td>
                          <td>{center.id}</td>
                          <td>
                            <InputField
                              type="text"
                              value={qualiphyKeys[center.id] || ''}
                              onChange={(e) => handleQualiphyKeyChange(center.id, e.target.value)}
                              placeholder="Enter Qualiphy API Key"
                              disabled={!selectedCenters[center.id]}
                              required={selectedCenters[center.id]}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No centers available.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ResponsiveTableWrapper>
            )}

            <ButtonContainer>

              <SubmitButton onClick={handleSubmit} type="submit" disabled={isSubmitting || editableApiKey === ''}>
                {isSubmitting ? <ClipLoader color={'#fff'} loading={true} size={24} /> : 'Submit'}
              </SubmitButton>

            </ButtonContainer>
          </ContentContainer>
        </Content>
      </MainSection>
    </Wrapper>
  );
};

export default DisplayPage;

// Styled Components

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f8f9fa;
`;

const MainSection = styled.section`
  display: flex;
  flex-grow: 1;
  padding: 50px 20px;
  overflow-y: auto;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #1f2937;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center; /* Center the title for emphasis */
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  width: 220px;
  margin-right: 20px;

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const InputField = styled.input`
  padding: 10px 15px;
  width: 100%;
  max-width: 600px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #1f2937;
    box-shadow: 0 0 5px rgba(31, 41, 55, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const ResponsiveTableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;

  th, td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
  }

  th {
    background-color: #1f2937;
    color: white;
    font-weight: bold;
  }

  td {
    background-color: #ffffff;
  }

  tr:nth-child(even) td {
    background-color: #f2f2f2;
  }

  input[type='checkbox'] {
    transform: scale(1.2);
    cursor: pointer;
  }

  input[type='text'] {
    padding: 8px;
    width: 95%;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: border-color 0.3s;

    &:focus {
      border-color: #1f2937;
      outline: none;
    }
  }

  @media (max-width: 768px) {
    th, td {
      padding: 10px;
      font-size: 12px;
    }

    input[type='checkbox'] {
      transform: scale(1);
    }

    input[type='text'] {
      width: 90%;
    }
  }
`;

const SubmitButton = styled.button`
  background-color: #1f2937;
  color: white;
  padding: 12px 25px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s, box-shadow 0.3s;
  min-width: 100px;

  &:hover {
    background-color: #374151;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns button to the right */
  margin-top: 30px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const Sidebar = styled.nav`
  background-color: #1f2937;
  color: white;
  padding: 20px;
  width: 250px;
  position: sticky;
  top: 0;
  border-radius: 10px;
  height: fit-content;
  margin-right: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 900px) {
    width: 200px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const SidebarTitle = styled.h3`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const NavItem = styled(NavLink)`
  display: block;
  padding: 10px 0;
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    color: #e5e7eb;
    background-color: #374151;
  }

  &.active {
    color: #2563eb;
    background-color: #1f2937;
  }
`;

export const CustomLink = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: #666;

  a {
    color: #1f2937;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #111827;
    }
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Toast = styled.div`
  background-color: ${(props) =>
    props.type === 'error' ? '#f8d7da' : '#d4edda'}; /* Softer background colors */
  color: ${(props) => (props.type === 'error' ? '#721c24' : '#155724')}; /* Darker text */
  border: 1px solid ${(props) => (props.type === 'error' ? '#f5c6cb' : '#c3e6cb')};
  padding: 15px 20px;
  border-radius: 10px; /* Rounded corners for a friendly look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px; /* Comfortable font size */
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.type === 'error' ? '#721c24' : '#155724')}; /* Match text color */
  font-size: 18px; /* Sleek button size */
  cursor: pointer;
  margin-left: 20px; /* Space between message and button */
  padding: 5px;
  line-height: 1;

  &:hover {
    color: ${(props) => (props.type === 'error' ? '#f5c6cb' : '#c3e6cb')}; /* Subtle hover effect */
  }
`;

// Section break for spacing
const SectionBreak = styled.div`
  flex-grow: 1;
`;

const Content = styled.div`
  flex: 1;
  max-width: 100%; /* Full width to ensure no overflow */
  justify-content: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in width calculations */

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  @media (max-width: 600px) {
    padding: 5px;
  }
`;

const ContentContainer = styled.div`
  background: white;
  padding: 2.5%;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in width */
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 1200px) {
    max-width: 100%; /* Use 100% width on medium screens */
  }

  @media (max-width: 900px) {
    max-width: 100%; /* Full width for tablets */
  }

  @media (max-width: 600px) {
    padding: 20px; /* Reduce padding on smaller screens */
    max-width: 100%; /* Full width on small devices */
  }
`;

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Header from './Header-New';
import ClipLoader from 'react-spinners/ClipLoader'; // Import ClipLoader
import { NavLink } from 'react-router-dom'; // Use NavLink for navigation
import { apiHost } from '../constants';

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    zenotiApiKey: '',
    zenotiApplicationId: '',
    screeningFrequencyInMonths: '',
    company: '',
    zenotiSecretKey: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setShowErrorBanner(false);
    setShowSuccessBanner(false);
    localStorage.setItem('zenotiApiKey', formData.zenotiApiKey);

    try {
      const response = await fetch(`${apiHost}/zenoti/register/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          zenotiApiKey: formData.zenotiApiKey,
          zenotiApplicationId: formData.zenotiApplicationId,
          screeningFrequencyInMonths: formData.screeningFrequencyInMonths,
          company: formData.company,
          zenotiSecretKey: formData.zenotiSecretKey || undefined,
        }),
      });

      const data = await response.json();
      if (data.message === 'User already registered') {
        setMessage('User already exists');
        setShowSuccessBanner(true);
      } else {
        setMessage('Registration successful');
        setShowSuccessBanner(true);
      }

    } catch (error) {
      setMessage('Error during registration');
      setShowErrorBanner(true);
      console.error('Error during registration:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeBanner = () => {
    setShowErrorBanner(false);
    setShowSuccessBanner(false);
  };

  // Auto dismiss banners after 10 seconds
  useEffect(() => {
    if (showErrorBanner) {
      const timer = setTimeout(() => {
        setShowErrorBanner(false);
        setMessage(''); // Clear message
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showErrorBanner]);

  useEffect(() => {
    if (showSuccessBanner) {
      const timer = setTimeout(() => {
        setShowSuccessBanner(false);
        setMessage(''); // Clear message
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessBanner]);

  return (
    <Wrapper>
      <Header />
      <MainSection>
        <ToastContainer>
          {showErrorBanner && (
            <Toast type="error">
              {message}
              <SectionBreak /> {/* Section break between message and close button */}
              <CloseButton type="error" onClick={closeBanner}>&times;</CloseButton>
            </Toast>
          )}
          {showSuccessBanner && (
            <Toast type="success">
              {message}
              <SectionBreak />
              <CloseButton type="success" onClick={closeBanner}>&times;</CloseButton>
            </Toast>
          )}
        </ToastContainer>
        <Sidebar>
          <SidebarTitle>Navigation</SidebarTitle>
          <NavItem exact to="/zenoti/getting/started">Get Started</NavItem>
          <NavItem exact to="/zenoti/register">Register / Account Details</NavItem>
          <NavItem exact to="/zenoti/displayCenters">Center Management</NavItem>
          <NavItem exact to="/zenoti/displayServicesAndExams">Services & Exams Management</NavItem>
        </Sidebar>
        <Content>
          <FormContainer>
            <Title>Register Your Account</Title>
            <Subtitle>Get started with QUALIPHY.</Subtitle>
            <Form onSubmit={handleSubmit}>
              <InputField
                type="text"
                name="zenotiApiKey"
                placeholder="Zenoti API Key"
                value={formData.zenotiApiKey}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="zenotiApplicationId"
                placeholder="Zenoti Application Id"
                value={formData.zenotiApplicationId}
                onChange={handleChange}
                required
              />
              <InputField
                type="number"
                name="screeningFrequencyInMonths"
                placeholder="Screening Frequency (in months)"
                value={formData.screeningFrequencyInMonths}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="company"
                placeholder="Company Name"
                value={formData.company}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="zenotiSecretKey"
                placeholder="Zenoti Secret Key (Optional)"
                value={formData.zenotiSecretKey}
                onChange={handleChange}
              />
              <SubmitButton type="submit" disabled={isLoading}>
                {isLoading ? <ClipLoader color={'#fff'} loading={true} size={24} /> : 'Register'}
              </SubmitButton>
            </Form>
          </FormContainer>
        </Content>
      </MainSection>
    </Wrapper>
  );
};

export default RegistrationPage;

// Styled Components

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f8f9fa;
`;

const MainSection = styled.section`
  display: flex;
  flex-grow: 1;
  padding: 50px 20px;
  overflow-y: auto;
`;

const FormContainer = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 2.5%;
  max-width: 450px;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h2`
  font-size: 30px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #80bdff;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  background-color: #1f2937;
  color: white;
  padding: 15px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;

  &:hover {
    background-color: #111827;
    transform: translateY(-3px);
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Toast = styled.div`
  background-color: ${(props) =>
    props.type === 'error' ? '#f8d7da' : '#d4edda'}; /* Softer background colors */
  color: ${(props) => (props.type === 'error' ? '#721c24' : '#155724')}; /* Darker text */
  border: 1px solid ${(props) => (props.type === 'error' ? '#f5c6cb' : '#c3e6cb')};
  padding: 15px 20px;
  border-radius: 10px; /* Rounded corners for a friendly look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px; /* Comfortable font size */
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.type === 'error' ? '#721c24' : '#155724')}; /* Match text color */
  font-size: 18px; /* Sleek button size */
  cursor: pointer;
  margin-left: 20px; /* Space between message and button */
  padding: 5px;
  line-height: 1;

  &:hover {
    color: ${(props) => (props.type === 'error' ? '#f5c6cb' : '#c3e6cb')}; /* Subtle hover effect */
  }
`;

// Section break for spacing
const SectionBreak = styled.div`
  flex-grow: 1;
`;

const Sidebar = styled.nav`
  background-color: #1f2937;
  color: white;
  padding: 20px;
  width: 250px;
  position: sticky;
  top: 0;
  border-radius: 10px;
  height: fit-content;
  margin-right: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 900px) {
    width: 200px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const SidebarTitle = styled.h3`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const NavItem = styled(NavLink)`
  display: block;
  padding: 10px 0;
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    color: #e5e7eb;
    background-color: #374151;
  }

  &.active {
    color: #2563eb;
    background-color: #1f2937;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 100%; 
`;

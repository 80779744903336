import React from 'react';
import styled from 'styled-components';

const Header = () => {
  return (
    <HeaderContainer>
    <LogoContainer>
      <LogoImage src='/qualiphy.png' alt='Logo' />
    </LogoContainer>
  </HeaderContainer>
  );
};

export default Header;


const HeaderContainer = styled.header`
  background-color: #1f2937;
  color: #ffffff;
  padding: 15px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 25px;

  @media (max-width: 768px) {
    height: 30px;
  }
`;
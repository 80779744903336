import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Select from 'react-select';
import { apiHost } from '../constants';
import ClipLoader from 'react-spinners/ClipLoader';
import { NavLink, Link } from 'react-router-dom'; // Use NavLink for navigation
import Header from './Header-New';

const CenterServicesPage = () => {
  const location = useLocation();
  const zenotiApiKey = location.state?.zenotiApiKey || localStorage.getItem('zenotiApiKey') || ''; // Access Zenoti API Key from state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editableApiKey, setEditableApiKey] = useState(zenotiApiKey);
  const [webhookIntegrationKey, setWebhookIntegrationKey] = useState();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedExams, setSelectedExams] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader
      setShowErrorBanner(false);
      try {
        const requestBody = {
          zenotiApiKey: editableApiKey
        };

        const response = await fetch(`${apiHost}/zenoti/services`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const fetchedData = await response.json();

        if (fetchedData.success) {
          setServices(fetchedData.response.centerDetails);
          setWebhookIntegrationKey(fetchedData.response.qualiphyIntegrationKey);
          prepopulateSelectedExams(fetchedData.response.selectedData)
        } else {
          setErrorMessage(fetchedData.message);
          setShowErrorBanner(true); // Show error banner
        }
      } catch (error) {
        setErrorMessage("Error fetching services.");
        setShowErrorBanner(true); // Show error banner
      } finally {
        setLoading(false); // Hide loader
      }
    };

    if (editableApiKey) {
      fetchData();
    }
  }, [editableApiKey]);

  const handleExamChange = (centerId, serviceId, selectedOptions) => {
    const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedExams((prevSelected) => ({
      ...prevSelected,
      [centerId]: {
        ...prevSelected[centerId],
        [serviceId]: selectedIds,
      },
    }));
  };

  const prepopulateSelectedExams = (selectedData) => {
    const examsMap = {};
    try {
      selectedData.forEach((center) => {
        const centerId = center.centerId;

        // Initialize the center object if it doesn't exist
        if (!examsMap[centerId]) {
          examsMap[centerId] = {};
        }

        if (center.services && center.services.length > 0) {
          center.services.forEach((service) => {
            const serviceId = service.zenotiServiceId;

            // Prepopulate exams for the current service
            if (service.exams && service.exams.length > 0) {
              examsMap[centerId][serviceId] = service.exams.map((exam) => ({
                value: exam.qualiphyExamId,
                label: exam.qualiphyExamName,
                examId: exam.qualiphyExamId
              }));
            }
          });
        }
      });
    } catch (e) {
      console.error(e);
    }

    setSelectedExams(examsMap);

  };

  const updateZenotiApiKey = (newKey) => {
    localStorage.setItem('zenotiApiKey', newKey);
    setEditableApiKey(newKey);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = [];

    services.forEach((center) => {
      const centerName = center.centerName; // Assuming center has a 'name' property
      const centerId = center.centerId;
      const centerServices = [];

      center.centerServices.forEach((service) => {
        const exams = selectedExams[center.centerId]?.[service.id]?.map((examId) => ({
          value: Number.isInteger(examId) ? examId : examId.examId,
          label: center.centerExams.exams.find((exam) => exam.id === examId)?.title || center.centerExams.exams.find((exam) => exam.id == examId.examId)?.title,
        })) || [];

        if (exams.length > 0) {
          // Create an object for the service with its name and exams
          centerServices.push({
            serviceName: service.name,
            serviceId: service.id,
            exams: exams // Include the selected exams
          });
        }
      });

      if (centerServices.length > 0) {
        // Push center data into formattedData array
        formattedData.push({
          centerId: centerId,
          centerName: centerName,
          services: centerServices
        });
      }
    });

    let requestBody = {
      zenotiApiKey: editableApiKey,
      selectedData: formattedData
    }

    console.log("selectedData:", requestBody);

    setIsSubmitting(true); // Show loader

    try {
      const response = await fetch(`${apiHost}/zenoti/addServicesAndExams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setSuccessMessage(result.message || 'Services and Centers integrated successfully.');
        setShowSuccessBanner(true);
      } else {
        setErrorMessage(result.message || 'Failed to integrate centers. Please try again.');
        setShowErrorBanner(true);
      }
    } catch (error) {
      setErrorMessage('Error sending data. Please try again.');
      setShowErrorBanner(true);
      console.error('Error sending data:', error);
    } finally {
      setIsSubmitting(false); // Hide loader after request
    }
  };

  // Auto dismiss banners after 10 seconds
  useEffect(() => {
    if (showErrorBanner) {
      const timer = setTimeout(() => {
        setShowErrorBanner(false);
        setErrorMessage(''); // Clear message
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showErrorBanner]);

  useEffect(() => {
    if (showSuccessBanner) {
      const timer = setTimeout(() => {
        setShowSuccessBanner(false);
        setSuccessMessage(''); // Clear message
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessBanner]);

  const handleCloseErrorBanner = () => {
    setShowErrorBanner(false);
    setErrorMessage(''); // Clear message
  };

  const handleCloseSuccessBanner = () => {
    setShowSuccessBanner(false);
    setSuccessMessage(''); // Clear message
  };

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      maxWidth: '100%', // Ensure it doesn't overflow horizontally
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '40px', // Adjust as needed
      overflow: 'hidden',
      backgroundColor: '#f8fafc', // Light background for the control box
      borderColor: '#d1d5db', // Subtle gray border
      ':hover': {
        borderColor: '#1e40af', // Dark blue border on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '300px', // Set a limit for the dropdown height
      overflowY: 'auto', // Enable vertical scrolling
      backgroundColor: '#ffffff', // White background for dropdown
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#1f2937', // Dark grayish-blue background for selected values
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#f9fafb', // Very light gray text for contrast
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#f9fafb', // Light gray remove button color
      ':hover': {
        backgroundColor: '#d3d9db',
        color: 'white', // White text on hover for remove
      },
    }),
  };

  return (
    <Wrapper>
      <Header />
      <MainSection>
        <ToastContainer>
          {showErrorBanner && (
            <Toast type="error">
              {errorMessage}
              <SectionBreak /> {/* Section break between message and close button */}
              <CloseButton type="error" onClick={handleCloseErrorBanner}>&times;</CloseButton>
            </Toast>
          )}
          {showSuccessBanner && (
            <Toast type="success">
              {successMessage}
              <SectionBreak />
              <CloseButton type="success" onClick={handleCloseSuccessBanner}>&times;</CloseButton>
            </Toast>
          )}
        </ToastContainer>
        <Sidebar>
          <SidebarTitle>Navigation</SidebarTitle>
          <NavItem exact to="/zenoti/getting/started">Get Started</NavItem>
          <NavItem exact to="/zenoti/register">Register / Account Details</NavItem>
          <NavItem exact to="/zenoti/displayCenters">Center Management</NavItem>
          <NavItem exact to="/zenoti/displayServicesAndExams">Services & Exams Management</NavItem>
        </Sidebar>
        <Content>
          <ContentContainer>
            <Title>Zenoti-Qualiphy Integration: Services' Exams Selection</Title>
            <FormSection>
              <FormWrapper>
                <Label htmlFor="zenotiApiKey">Zenoti API Key:</Label>
                <InputField
                  id="zenotiApiKey"
                  type="text"
                  value={editableApiKey}
                  onChange={(e) => updateZenotiApiKey(e.target.value)}
                  placeholder="Enter Zenoti API Key"
                  required
                />
              </FormWrapper>

              <FormWrapper>
                <Label htmlFor="webhookIntegrationKey">Webhook Integration Key:</Label>
                <InputField
                  id="webhookIntegrationKey"
                  type="text"
                  value={webhookIntegrationKey}
                  disabled
                />
              </FormWrapper>
            </FormSection>
            <CustomLink>
              You must select atleast one center with a correct Qualiphy API Key in order to see available services along with exam mappings from Qualiphy from <Link to="/zenoti/displayCenters">Center Management</Link>
            </CustomLink>

            {loading ? (
              <LoaderWrapper>
                <ClipLoader color={'#1f2937'} loading={true} size={50} />
              </LoaderWrapper>
            ) : (
              <>
                <ResponsiveTableWrapper>
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ width: '25%' }}>Zenoti Center Name</th>
                        <th style={{ width: '30%' }}>Zenoti Services</th> {/* Adjusted width */}
                        <th>Qualiphy Exams</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services && services.length > 0 ? (
                        services.map((center) => (
                          center &&
                            center.centerServices &&
                            center.centerServices.length > 0 ? (
                            center.centerServices.map((service, index) => (
                              <tr key={service.id}>
                                {index === 0 && (
                                  <TableCell
                                    rowSpan={center.centerServices.length}
                                    style={{ textAlign: 'center', width: '25%' }}
                                  >
                                    <CenterName>{center.centerName}</CenterName>
                                  </TableCell>
                                )}
                                <TableCell style={{ width: '30%' }}>{/* Adjusted width */}
                                  {service.name || 'Unnamed Service'}
                                </TableCell>
                                <TableCell>
                                  <Select
                                    isMulti
                                    options={
                                      center.centerExams &&
                                        center.centerExams.exams &&
                                        center.centerExams.exams.length > 0
                                        ? center.centerExams.exams.map((exam) => ({
                                          value: exam.id,
                                          label: exam.title,
                                        }))
                                        : []
                                    }
                                    value={
                                      center.centerExams &&
                                        center.centerExams.exams &&
                                        center.centerExams.exams.length > 0 ? (selectedExams[center.centerId]?.[service.id]?.map(
                                          (examId) => (
                                            {
                                              value: examId || examId.examId,
                                              label: center.centerExams.exams.find(
                                                (exam) => exam.id == examId
                                              )?.title || center.centerExams.exams.find(
                                                (exam) => exam.id == examId.examId
                                              )?.title || 'Unknown Exam',
                                            })
                                        ) || []) : []
                                    }
                                    onChange={(selectedOptions) =>
                                      handleExamChange(
                                        center.centerId,
                                        service.id,
                                        selectedOptions
                                      )
                                    }
                                    styles={customSelectStyles}
                                    placeholder="Select Exams"
                                  />
                                </TableCell>
                              </tr>
                            ))
                          ) : (
                            <tr key={center.centerId}>
                              <td colSpan={3}>No services available for {center.centerName}</td>
                            </tr>
                          )
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>No services available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </ResponsiveTableWrapper>
              </>
            )}
            <ButtonContainer>
              <SubmitButton
                onClick={handleSubmit}
                type="submit"
                disabled={isSubmitting || editableApiKey === ''}
              >
                {isSubmitting ? (
                  <ClipLoader color={'#fff'} loading={true} size={24} />
                ) : (
                  'Submit'
                )}
              </SubmitButton>
            </ButtonContainer>
          </ContentContainer>
        </Content>
      </MainSection>
    </Wrapper>
  );

};

// Custom loader styles and animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const Wrapper = styled.div`
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f8f9fa;
`;

const MainSection = styled.section`
  display: flex;
  flex-grow: 1;
  padding: 50px 20px;
  overflow-y: auto;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #1f2937;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center; /* Center the title for emphasis */
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  width: 220px;
  margin-right: 20px;

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const InputField = styled.input`
  padding: 10px 15px;
  width: 100%;
  max-width: 600px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #1f2937;
    box-shadow: 0 0 5px rgba(31, 41, 55, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const ResponsiveTableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  height: 400px; /* Set a fixed height for the table container */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 1px solid #ced4da; /* Attractive border */
  border-radius: 5px; /* Rounded corners for a softer look */
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }

  &::-webkit-scrollbar-thumb {
    background: #1f2937; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #374151; /* Darker color on hover */
  }

  @media (max-width: 768px) {
    height: 300px; /* Adjust height for smaller screens */
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;

  th, td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
  }

  th {
    background-color: #1f2937;
    color: white;
    font-weight: bold;
    position: sticky; /* Makes the header sticky */
    top: 0; /* Sticks the header to the top */
    z-index: 10; /* Ensures the header is above other content */
  }

  td {
    background-color: #ffffff;
  }

  tr:nth-child(even) td {
    background-color: #f2f2f2;
  }

  input[type='checkbox'] {
    transform: scale(1.2);
    cursor: pointer;
  }

  input[type='text'] {
    padding: 8px;
    width: 95%;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: border-color 0.3s;

    &:focus {
      border-color: #1f2937;
      outline: none;
    }
  }

  @media (max-width: 768px) {
    th, td {
      padding: 10px;
      font-size: 12px;
    }

    input[type='checkbox'] {
      transform: scale(1);
    }

    input[type='text'] {
      width: 90%;
    }
  }
`;

const TableCell = styled.td`
  vertical-align: center;
  text-align: left;
  font-size: 14px;
`;

const CenterName = styled.span`
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns button to the right */
  margin-top: 30px;
`;

const SubmitButton = styled.button`
  background-color: #1f2937;
  color: white;
  padding: 12px 25px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s, box-shadow 0.3s;
  min-width: 100px;

  &:hover {
    background-color: #374151;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const Sidebar = styled.nav`
  background-color: #1f2937;
  color: white;
  padding: 20px;
  width: 250px;
  position: sticky;
  top: 0;
  border-radius: 10px;
  height: fit-content;
  margin-right: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 900px) {
    width: 200px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const SidebarTitle = styled.h3`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const NavItem = styled(NavLink)`
  display: block;
  padding: 10px 0;
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    color: #e5e7eb;
    background-color: #374151;
  }

  &.active {
    color: #2563eb;
    background-color: #1f2937;
  }
`;

export const CustomLink = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: #666;

  a {
    color: #1f2937;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #111827;
    }
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Toast = styled.div`
  background-color: ${(props) =>
    props.type === 'error' ? '#f8d7da' : '#d4edda'}; /* Softer background colors */
  color: ${(props) => (props.type === 'error' ? '#721c24' : '#155724')}; /* Darker text */
  border: 1px solid ${(props) => (props.type === 'error' ? '#f5c6cb' : '#c3e6cb')};
  padding: 15px 20px;
  border-radius: 10px; /* Rounded corners for a friendly look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px; /* Comfortable font size */
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.type === 'error' ? '#721c24' : '#155724')}; /* Match text color */
  font-size: 18px; /* Sleek button size */
  cursor: pointer;
  margin-left: 20px; /* Space between message and button */
  padding: 5px;
  line-height: 1;

  &:hover {
    color: ${(props) => (props.type === 'error' ? '#f5c6cb' : '#c3e6cb')}; /* Subtle hover effect */
  }
`;

// Section break for spacing
const SectionBreak = styled.div`
  flex-grow: 1;
`;

const Content = styled.div`
  flex: 1;
  max-width: 100%; /* Full width to ensure no overflow */
  justify-content: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in width calculations */

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  @media (max-width: 600px) {
    padding: 5px;
  }
`;

const ContentContainer = styled.div`
  background: white;
  padding: 2.5%;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in width */
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 1200px) {
    max-width: 100%; /* Use 100% width on medium screens */
  }

  @media (max-width: 900px) {
    max-width: 100%; /* Full width for tablets */
  }

  @media (max-width: 600px) {
    padding: 20px; /* Reduce padding on smaller screens */
    max-width: 100%; /* Full width on small devices */
  }
`;

export default CenterServicesPage;

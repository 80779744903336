import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Toaster, toast } from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import EndpointPage from './components/EndpointPage';
import docsService from './services/docs-service';
import IntroPage from './components/IntroPage';
import IntroPageBeta from './components/IntroPageBeta';
import LandingPage from './components/GettingStarted';
import UserRegistration from './components/UserRegistration';
import DisplayCenters from './components/DisplayCenters';
import DisplayServicesAndExams from './components/DisplayServicesAndExams';
import NotFoundPage from './components/NotFoundPage'; // Import the NotFoundPage component

const Layout = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f4f7fc;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Main = styled.main`
  flex: 1;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function App() {
  return (
    <Router>
      <AppRoutes />
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: 'green',
              color: 'white',
            },
          },
          error: {
            style: {
              background: 'red',
              color: 'white',
            },
          },
        }}
        position='top-right'
        reverseOrder={false}
      />
    </Router>
  );
}

function AppRoutes() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [endpoints, setEndpoints] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await docsService.getDocs();
        if (response?.error) {
          toast.error('Something went wrong. Please try again!');
        } else {
          setEndpoints(response);
        }
      } catch (error) {
        toast.error('Something went wrong. Please try again!');
      } finally {
        setLoading(false);
      }
    };

    fetchDocs();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Function to determine if the current path is a docs or beta path
  const isDocsOrBetaRoute = location.pathname.startsWith('/docs') || location.pathname.startsWith('/beta');

  return (
    <>
      {isDocsOrBetaRoute && <Header toggleSidebar={toggleSidebar} />}

      <Routes>
        {/* Routes without Layout */}
        <Route path='/zenoti/getting/started' element={<LandingPage />} />
        <Route path='/zenoti/register' element={<UserRegistration />} />
        <Route path='/zenoti/displayCenters' element={<DisplayCenters />} />
        <Route path='/zenoti/displayServicesAndExams' element={<DisplayServicesAndExams />} />

        {/* Routes with Layout (for docs and beta) */}
        {isDocsOrBetaRoute && (
          <Route
            path='*'
            element={
              <Layout>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} endpoints={endpoints} />
                <Main>
                  {loading ? (
                    <LoaderContainer>
                      <ClipLoader size={50} color={'#123abc'} loading={loading} />
                    </LoaderContainer>
                  ) : (
                    <Routes>
                      {/* Docs routes */}
                      <Route path='/docs/api/intro' element={<IntroPage />} />
                      <Route path='/docs/api' element={<IntroPage />} />
                      <Route path='/docs' element={<IntroPage />} />
                      {endpoints
                        .filter((endpoint) => endpoint.isBeta === false)
                        .map((endpoint) => (
                          <Route
                            key={endpoint.id}
                            path={`/docs/api/${endpoint.title.toLowerCase().replace(/ /g, '-')}`}
                            element={<EndpointPage endpoint={endpoint} />}
                          />
                        ))}

                      {/* Beta routes */}
                      <Route path='/beta/api/intro' element={<IntroPageBeta />} />
                      <Route path='/beta/api' element={<IntroPage />} />
                      <Route path='/beta' element={<IntroPage />} />
                      {endpoints
                        .filter((endpoint) => endpoint.isBeta === true)
                        .map((endpoint) => (
                          <Route
                            key={endpoint.id}
                            path={`/beta/api/${endpoint.title.toLowerCase().replace(/ /g, '-')}`}
                            element={<EndpointPage endpoint={endpoint} />}
                          />
                        ))}

                      {/* Fallback for undefined routes */}
                      <Route path='*' element={<NotFoundPage />} />
                    </Routes>
                  )}
                </Main>
              </Layout>
            }
          />
        )}

        {/* Fallback for undefined routes without layout */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
